import Head from 'next/head';
import { renderMetaTags, useQuerySubscription } from 'react-datocms';
import { request } from 'lib/datocms';
import dynamic from 'next/dynamic';
import {
  metaTagsFragment,
  responsiveImageFragment,
  contentBlocks,
  contentFragments,
  pageHeroImageFragment,
  menuFragment,
  pageCTAFragment,
  quoteSliderFragment,
  organisationSliderFragment,
} from 'lib/fragments';

import { Layout, FeaturedIntro, GeneralPageBody, PageCTA, PageHero } from 'components';
import { useGlobalContext } from '../lib/contexts/global-context';
import { useEffect } from 'react';

export async function getStaticProps({ preview }) {
  const graphqlRequest = {
    query: `
      {
        menu {
          ...menuFragment
        }
        homePage {
          id
          introduction(markdown: true)
          title
          ${pageHeroImageFragment}
          content {
            ${contentBlocks}
          }
          seo: _seoMetaTags {
            ...metaTagsFragment
          }
          callToAction {
            ...pageCTAFragment
          }
          quoteSlider {
            ...quoteSliderFragment
          }
          organisationSlider {
            ...organisationSliderFragment
          }
        }
      }

      ${responsiveImageFragment}
      ${metaTagsFragment}
      ${contentFragments}
      ${menuFragment}
      ${pageCTAFragment}
      ${quoteSliderFragment}
      ${organisationSliderFragment}
    `,
    preview,
  };

  return {
    props: {
      subscription: preview
        ? {
            ...graphqlRequest,
            initialData: await request(graphqlRequest),
            token: process.env.NEXT_DATOCMS_API_TOKEN,
            environment: process.env.NEXT_DATOCMS_ENVIRONMENT || null,
          }
        : {
            enabled: false,
            initialData: await request(graphqlRequest),
          },
    },
    revalidate: 120,
  };
}

// Don't pre-render these components
const DynamicQuoteSlider = dynamic(() => import('components/quote/quote-slider'), { ssr: false });
const DynamicOrganisationSlider = dynamic(() => import('components/organisation/organisation-slider'), { ssr: false });

export default function HomePage({ subscription }) {
  const {
    data: { homePage, menu },
  } = useQuerySubscription(subscription);

  const callToAction = homePage.callToAction;
  const { setCurrentNavTitle } = useGlobalContext();

  const metaTags = homePage.seo;
  const heroImage = homePage.image;
  const quoteSlider = homePage.quoteSlider;
  const organisationSlider = homePage.organisationSlider;

  useEffect(() => {
    setCurrentNavTitle('');
  }, [setCurrentNavTitle])

  return (
    <>
      <Layout preview={subscription.preview} menu={menu}>
        <Head>{renderMetaTags(metaTags)}</Head>

        {heroImage && <PageHero responsiveImage={heroImage.responsiveImage} title={homePage.title} forHomePage />}

        { homePage.introduction && <FeaturedIntro introduction={homePage.introduction} /> }

        <section className="PageBody PageBody--home">
          <GeneralPageBody content={homePage.content} />
        </section>

        {!!quoteSlider && <DynamicQuoteSlider {...quoteSlider} />}

        {!!organisationSlider && <DynamicOrganisationSlider {...organisationSlider} />}

        {!!callToAction && (
          <PageCTA
            title={callToAction.title}
            caption={callToAction.caption}
            btnLabel={callToAction.buttonLabel}
            btnURL={callToAction.buttonUrl}
          />
        )}
      </Layout>
    </>
  );
}
